<template>
  <div>
    <audio v-for="(audio,key) in audios" v-if="audio" :ref="key" :src="audio.url" :autoplay="audiosConfig[key].autoPlay" :loop="audiosConfig[key].loop"
           @canplay="oncanplay(key)" @play="onplay(key)" @pause="onpause(key)" @ended="ended(key)" @playing="onplaying(key)" @waiting="onwaiting(key)"></audio>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {
      }
    },
    computed: {
      audios() {
        return { ...this.config.audios, ...this.publicData.audios, }
      },
      audiosConfig() {
        return { ...this.config.audiosConfig, ...this.publicData.audiosConfig, }
      },
    },
    watch: {
      audios(val, oldval) {
        setTimeout(this.renewAudio, 1)
      },
      audiosConfig(val, oldval) {
        setTimeout(this.renewAudio, 1)
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
          play: this.tryPlay,
          pause: this.pause,
          resume: this.resume,
          playAll: this.playAll,
          pauseAll: this.pauseAll,
          resumeAll: this.resumeAll,
        }
      })
    },
    destroyed() {
    },
    methods: {
      oncanplay(key) {
        var c = this.audiosConfig
        console.log(key, 'oncanplay', c)
        if (!c[key]) {
          c[key] = {}
        }
        c[key].ready = true
        c[key].play = false
        c[key].pause = false
        c[key].waiting = false
        this.getFunc('changePublicData')({ audiosConfig: c })
        if (c[key].autoPlay) {
          console.log(key, 'oncanplay play')
          setTimeout(this.tryPlay, 10, key)
        }
      },
      onplay(key) {
        console.log(key, 'onplay')
        var c = this.audiosConfig
        if (!c[key]) {
          c[key] = {}
        }
        c[key].play = true
        c[key].pause = false
        this.getFunc('changePublicData')({ audiosConfig: c })
      },
      ended(key) {
        console.log(key, 'ended')
        var c = this.audiosConfig
        if (!c[key]) {
          c[key] = {}
        }
        c[key].play = false
        this.getFunc('changePublicData')({ audiosConfig: c })
      },
      onpause(key) {
        console.log(key, 'onpause')
        var c = this.audiosConfig
        if (!c[key]) {
          c[key] = {}
        }
        c[key].play = false
        c[key].pause = true
        this.getFunc('changePublicData')({ audiosConfig: c })
      },
      onplaying(key) {
        console.log(key, 'onplaying')
        var c = this.audiosConfig
        if (!c[key]) {
          c[key] = {}
        }
        c[key].waiting = false
        this.getFunc('changePublicData')({ audiosConfig: c })
      },
      onwaiting(key) {
        console.log(key, 'onwaiting')
        var c = this.audiosConfig
        if (!c[key]) {
          c[key] = {}
        }
        c[key].waiting = true
        this.getFunc('changePublicData')({ audiosConfig: c })
      },
      tryPlay(key) {
        if (!this.$refs[key]) {
          setTimeout(this.tryPlay, 300, key)
          return
        }
        this.$refs[key][0].play().then(() => {
        }).catch((err) => {
          console.error(err)
          setTimeout(this.tryPlay, 300, key)
        })
      },
      pause(key) {
        if (!this.$refs[key]) {
          return
        }
        this.$refs[key][0].pause()
      },
      resume(key) {
        if (!this.$refs[key]) {
          return
        }
        var c = this.audiosConfig[key]
        if (c.play == false && c.pause == true) {
          this.tryPlay(key)
        }
      },
      playAll() {
        for (var key in this.audios) {
          this.tryPlay(key)
        }
      },
      pauseAll() {
        for (var key in this.audios) {
          this.pause(key)
        }
      },
      resumeAll() {
        for (var key in this.audios) {
          this.resume(key)
        }
      },
      play(key) {
        //this.$refs[key][0].play()
        //var c = this.audiosConfig
        //if (!c[key]) {
        //  c[key] = {}
        //}
        //c[key].play = true
        //this.getFunc('changePublicData')({ audiosConfig: c })
      },
      renewAudio() {
        for (var i in this.audios) {
          if (this.audiosConfig[i]) {
            if (!this.$refs[i]) {
              continue
            }
            if (this.audiosConfig[i].play) {
              this.$refs[i][0].play()
            }
            if (this.audiosConfig[i].play === false) {
              this.$refs[i][0].pause()
            }
            if (this.audiosConfig[i].volume) {
              this.$refs[i][0].volume = this.audiosConfig[i].volume
            } else if (this.audiosConfig[i].priority) {
              var mp = this.getMaxPriority()
              var vol = this.audiosConfig[i].priority / mp
              if (vol > 1) {
                vol = 1
              }
              if (vol < 0) {
                vol = 0
              }
              this.$refs[i][0].volume = vol
            }
          }
        }
      },
      getMaxPriority() {
        var maxPriority = 1
        for (var i in this.audios) {
          if (this.audiosConfig[i].priority > maxPriority && this.audiosConfig[i].play) {
            maxPriority = this.audiosConfig[i].priority
          }
        }
        return maxPriority
      }
    },
  }
</script>
<style scoped>
  .fine {
    position: absolute;
    top: 1.4rem;
    right: 1.4rem;
    font-size: 1.5rem;
    text-shadow: 0 0 2px red;
    color: orange;
    font-weight: bolder;
  }
</style>
